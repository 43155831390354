import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import {CopyToClipboard} from "react-copy-to-clipboard";
import * as styles from "../styles/contact.css"
import { navigate } from 'gatsby-link'

import {
  Nudge,
  Container,
  Section,
  Heading,
  Text,
  ButtonList,
  Kicker,
  HomepageLink,
  HomepageImage,
  Space,
  Button,
  Blockquote,
  SuperHeading,
  Subhead,
} from "../components/ui"
import { style } from "@vanilla-extract/css";

interface ContactProps {
  data: {
    aboutPage: {
      id: string
      title: string
      description: string
      image: { id: string; url: string }
    }
  }
}
function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export default function Contact(props: ContactProps) {
  const { aboutPage } = props.data
  const [state, setState] = React.useState({})

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch((error) => alert(error))
  } 
  return (
    <Layout {...aboutPage}>
      <Section>
        <Container>
          <Heading>Contact us</Heading>
        </Container>
      </Section>
      <form 
        name="contact" 
        method="POST" 
        data-netlify="true" 
        netlify-honeypot="bot-field"
        action="/thanks/"
        onSubmit={handleSubmit}
        >
          <input type="hidden" name="bot-field" />
          <input type="hidden" name="form-name" value="contact" />
          <Container>
            <Kicker className={styles.label}>Your Email</Kicker>
            <input className={styles.input} type="email" name="email" placeholder="myemail@example.com" onChange={handleChange}/>
          </Container>
          <Space size={5}/>
          <Container>
            <Kicker className={styles.label}>Message</Kicker>
            <textarea name="message" className={[styles.input, styles.textarea].join(" ")} placeholder="Write your message" onChange={handleChange}></textarea>
          </Container>
          <Container>
            <button className={styles.send} type="submit">Send Message</button>
          </Container>
    </form>
    <Section>
      <Container>
        <Subhead>Or write to us directly</Subhead>
        <CopyToClipboard text={"contact@mirdin.com123"}>
          <>
            <Kicker className={styles.ourEmail}>contact@mirdin.com</Kicker>
          </>
        </CopyToClipboard>
      </Container>
    </Section>
    </Layout>
  )
}

export const query = graphql`
  {
    contactPage {
      id
      title
      description
    }
  }
`
